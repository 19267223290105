import $ from 'jquery'

import { gsap } from "gsap";
import { random } from "gsap/gsap-core";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

gsap.from('.navbar .logo', {
  scrollTrigger:{
    trigger: ".page2",
    start: "top 80%",
    toggleActions: "play complete none reverse"
  },
  alpha:0.0,duration:1
  }
);


gsap.from(".page1 .fango", {
  alpha:0.0,y:"5%",duration:2,delay:1});


gsap.to(".page1 .title", {
  scrollTrigger:{
    trigger: ".page1",
    start: "top",
    end: "10%",
    scrub: 0.5,
  },
  alpha:0,
  y:"-5vh",
});

gsap.to(".page1 .fango", {
  scrollTrigger:{
    trigger: ".page1",
    start: "top",
    end: "bottom",
    scrub: 0.5,
  },
  y:"100vh"
});

//https://codepen.io/GreenSock/pen/LYVKWGo
gsap.from('.page2 h1',{
  scrollTrigger:{
    trigger:'.page2',
    start:'top 10%',
   
    toggleActions: "play complete none reverse"
  },
  alpha:0,duration:1
})

gsap.from('.page2 .phone',{
  scrollTrigger:{
    trigger:'.page2',
    start:'top 10%',
   
    toggleActions: "play complete none reverse"
  },
  alpha:0,duration:3
})


$('p.fadein').each(
  function(){
    let target = $(this)

    gsap.from(target,{
      scrollTrigger:{
        trigger:target,
        start:'top 80%',
        toggleActions: "play complete none reverse"
      },
      alpha:0.0,
      duration:1
    })
  }
)


//-page4

gsap.from('figure.unveil img',{
  scrollTrigger:{
    trigger:'figure.unveil',
    start:'top center',
    toggleActions: "play complete none reverse"
  },
  alpha:0,
 
})

//https://greensock.com/forums/topic/18250-trigger-animation-only-on-clicked-element-using-class-selector/
$('figure.cover').each(
  function(){
    
    let targetDiv  = $(this).find('div')
    let targetImg = $(this).find('img')
    let trigger = $(this)

    let scrollTrigger = {
      trigger:trigger,
      start:'top 80%',
      toggleActions: "play complete none reverse"
    }
    if(targetDiv.length > 0){
      gsap.from(targetDiv[0],{
        scrollTrigger:scrollTrigger,
        y:'100%',alpah:0.0,duration:.5
      })
    }
    if(targetImg.length > 0){
      gsap.from(targetImg[0],{
        scrollTrigger:scrollTrigger,
        y:'100%',duration:1,delay:0.5
      })
    }
  }
)

$('figure.video').each(
  function(){
    let video  = $(this).find('video')
    let trigger = $(this)
    video.css('opacity', '0.0')
    gsap.to(video,{
      scrollTrigger:{
        trigger:trigger,
        start:'top center',
        onToggle: self => self.isActive ? video[0].play() : video[0].pause()
      },
      delay:1,
      alpha:1
    })
  }
)
